import React from "react";
import axios from "axios";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      axiosGet: "",
    };
  }

  componentDidMount() {
    // make axios request set the
    axios.get("/api/v1/telemetry/ping").then((res) => {
      console.log("The res is: ", res);
      var results = res.data.message;
      this.setState({ axiosGet: results });
    });
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <h1>Solutions Link!</h1>
        <h3>The server is up and running: </h3>
        <h2> {this.state.axiosGet}</h2>
      </div>
    );
  }
}
export default Home;
